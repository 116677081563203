<template>
  <div>
    <main v-if="page == 'start'" class="content-500">
      <section id="headerTitle">
        <h1>Events</h1>
        <h2>in deiner Umgebung</h2>
      </section>
      <section id="headerSearch">
        <form>
          <div class="field">
            <form>
              <SimpleTypeahead id="city_search" placeholder="Suche nach Orten..." :items="cities" :min-input-length="1" :item-projection="item => {return item.name}" @select-item="selectCity" @on-input="loadCitys" />
              <button @click="getGPS" type="button" class="button bgcolor-blue color-white" style="padding: 2px 8px;">
                <svg width="32px" viewBox="0 0 91 91" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 91 91"><path d="M66.9 41.8c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4 0 11.3 20.4 32.4 20.4 32.4s20.4-21.1 20.4-32.4zM37 41.4c0-5.2 4.3-9.5 9.5-9.5s9.5 4.2 9.5 9.5c0 5.2-4.2 9.5-9.5 9.5-5.2 0-9.5-4.3-9.5-9.5z" fill="#ffffff" class="fill-000000" /></svg>
              </button>
            </form>
          </div>
        </form>
      </section>
      <section v-if="cityName == null && cityItems != ''" id="locationscontainer" class="pad-16">
        <div class="pad-16 br-10">
          <div class="grid-12 header">
            <div class="col-sp-12 row-sp-12">
              <h4 class="color-white">
                Deine <span class="color-blue f-bold">Standorte</span>
              </h4>
            </div>
          </div>
          <div class="grid-12">
            <cityItems v-for="citems in cityItems" :city-items="citems" :key="citems.id" />
          </div>
        </div>
      </section>
      <section v-if="cityName != null" id="searchResultsHeader" class="pad-16">
        <div class="grid-12">
          <div class="col-sp-8 row-sp-12">
            <h2 style="font-size: 1.2em; padding-top: 8px;" class="f-normal">
              Events in <span class="f-bold color-blue">{{ cityName }}</span>
            </h2>
          </div>
          <div class="col-sp-4 row-sp-12 t-right">
            <button class="button color-white" style="padding: 3px 8px;" @click="filterModal = true;">
              <img src="/assets/images/icons/filter-white.svg" class="width-24">
            </button>
          </div>
        </div>
        <div id="activeFilter">
          <h4 v-if="selectedFilters != ''">
            Filter:
          </h4>
          <filterItems v-for="filter in filterItems" :filter="filter" :key="filter.id" :selected-filters="selectedFilters" @select-filter="selectFilter" />
        </div>
      </section>
      <section id="searchResults">
        <events v-for="event in eventsFiltered" :event="event" :key="event.id" />
        <p v-if="!eventsFiltered.length && cityName != null" class="t-center">
          Leider konnten wir keine passenden Events in  <span class="color-blue">{{ cityName }}</span> und deinen Filtern  finden
        </p>
      </section>
      <section v-if="cityName != null" id="searchResultsHeader" class="pad-16">
        <hr>
        <div class="grid-12">
          <div class="col-sp-8 row-sp-12">
            <h2 style="font-size: 1.2em; padding-top: 8px;" class="f-normal">
              Events in der Nähe von <span class="f-bold color-blue">{{ cityName }}</span>
            </h2>
          </div>
        </div>
      </section>
      <section id="searchResults">
        <events v-for="event in nearestEventsFiltered" :event="event" :key="event.id" />
        <p v-if="eventsFiltered.length && !nearestEventsFiltered.length" class="t-center">
          Leider konnten wir keine passenden Events in der Nähe von <span class="color-blue">{{ cityName }}</span> und deinen Filtern finden
        </p>
      </section>
    </main>
    <transition name="modal" id="filterModal">
      <div class="vueModal" v-if="filterModal">
        <div class="vueModalOuter" @click="filterModal = false;" style="position: relative;" />
        <div class="vueModalInner bottom bgcolor-second">
          <div class="t-center pad-8">
            <h2 class="color-blue f-bold">
              Eventsfilter
            </h2>
            <div>
              <filterItems v-for="filter in filterItems" :filter="filter" :key="filter.id" :selected-filters="selectedFilters" @select-filter="selectFilter" />
            </div>
            <div class="pad-4" />
            <div class="field t-left">
              <label class="color-white f-bold">Datum</label>
              <input type="date" class="pad-12 bgcolor-black color-white br-10" style="border: none; width: 100%; box-sizing: border-box;" v-model="filterDate">
            </div>
            <div class="pad-8" />
            <button @click="selectedFilters = []; filterDate = false" class="button fluid bgcolor-black color-white" style="margin-right: 8px;">
              Filter zurücksetzen
            </button>
            <div class="pad-4" />
            <button @click="filterModal = false;" class="button fluid bgcolor-blue color-white">
              Events Filtern
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import events from "@/components/home/event";
import filterItems from "@/components/home/filter";
import cityItems from "@/components/home/myCities";


import SimpleTypeahead from 'vue3-simple-typeahead'
import {aSyncData, stringToDate} from "@/modules/main";
import {notificationStore} from "@/store/notificationStore";
export default {
  name: "Home",
  title: "Home",
  components: {
    'events': events,
    'filterItems': filterItems,
    'cityItems': cityItems,
    SimpleTypeahead
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data(){
    return {
      city: null,
      cityName: null,
      events: [],
      nearestEvents: [],
      page: 'start',
      cities: [],
      searchString: '',
      filterModal: false,
      filterItems: '',
      cityItems: '',
      selectedFilters: [],
      filterDate: false
    }
  },
  computed: {
    eventsFiltered(){
      const _this = this
      let events = this.events

      if(_this.selectedFilters.length){
        events = events.filter(e => {
          return _this.selectedFilters.every(f => {
            return e.labels.split(',').some(g => {
              return parseInt(g) === parseInt(f)
            })
          })
        })
      }

      return events
    },
    nearestEventsFiltered(){
      const _this = this
      let nearestEvents = this.nearestEvents

      if(_this.selectedFilters.length){
        nearestEvents = nearestEvents.filter(e => {
          return _this.selectedFilters.every(f => {
            return e.labels.split(',').some(g => {
              return parseInt(g) === parseInt(f)
            })
          })
        })
      }

      if(_this.filterDate){
        const time = stringToDate(_this.filterDate).getTime()
        nearestEvents = nearestEvents.filter(e => {
          return stringToDate(e.startTime).getTime() <= time && stringToDate(e.endTime).getTime() >= time
        })
      }

      return nearestEvents
    }
  },
  mounted() {
    this.config.loading = false

    this.getFilterItems()
    this.getMyCities()

    if(this.$route.params.id){
      this.getEventsByCity()
    }else if(this.auth.isAuth && this.user.ipCity?.name){
      this.selectCity(this.user.ipCity)
    }else if(!this.auth.isAuth){
      this.user.getCityByIP()
          .then(r => {
            this.selectCity(r)
          })
          .catch(e => {
            this.getEvents()
          })
    }else{
      this.getEvents()
    }
  },
  methods: {
    getGPS() {
      const _this = this
      this.user.getCityByGeo()
          .then(r => {
            _this.city = r
            _this.cityName = r.name
            _this.selectCity(_this.city)
          })
          .catch(e => {
            console.log(e)
          })
    },
    selectFilter(id){
      if(this.selectedFilters.includes(id)){
        this.selectedFilters.splice(this.selectedFilters.indexOf(id), 1)
      }else{
        this.selectedFilters.push(id)
      }
    },
    getEvents(){
      const _this = this
      aSyncData('home/getEvents')
          .then(r => {
            if(r.status === 250){
              _this.events = r.data
            }
          })
          .catch(e => {})
    },
    getEventsByCity (){
      const _this = this
      aSyncData('home/getCityById', {cityId: this.$route.params.id})
          .then(r => {
            if(r.status === 250){
              _this.city = r.data
              _this.cityName = r.data.name
              this.selectCity(r.data)
            }
          })
          .catch(e => {})
    },
    getMyCities(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('limit', 3)
      this.axios.post('home/getMyCities', fD)
          .then((response) => {
            if(response.status === 250){
              _this.cityItems = response.data
            }
          })
    },
    getFilterItems(){
      const _this = this
      this.axios.post('home/getFilterItems')
          .then((response) => {
            if(response.status === 250){
              _this.filterItems = response.data
            }
          })
    },
    loadCitys(event){
      const _this = this
      this.searchString = event.input
      aSyncData('home/cities', {
            'search': this.searchString
          })
          .then(r => {
            if(r.status === 250 && _this.searchString === event.input) _this.cities = r.data
          })
          .catch(e => {
            console.error(e)
          })
    },
    selectCity(item){
      this.city = item
      this.cityName = item.name
      const _this = this
      aSyncData('home/getEventsByCity', {
        cityId: item.id
      })
        .then(r => {
          if(r.status === 250){
            _this.events = r.data.events
            _this.nearestEvents = r.data.nearestEvents
          }
        })
    },
  }
}
</script>

<style scoped>
main{
  padding-top: 80px;
}
section#headerTitle h1{
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 2.2em;
}
section#headerTitle h2{
  font-family: 'Helvetica';
  font-weight: 100;
  text-align: center;
  font-size: 1em;
}
section#headerSearch{
  padding: 16px;
  clear: both;
}

section#headerSearch button{
  float: right;
}
section#searchResultsHeader h2{
  font-family: 'Helvetica';
  font-weight: 100;
}
#activeFilter{
  padding-top: 16px;
}
#activeFilter h4{
  display: inline;
  margin-right: 4px;
  font-family: 'Helvetica';
  font-weight: 100;
  color: rgba(255,255,255,0.8);
  font-size: 0.9em;
}
#activeFilter span{
  display: none;
}
#activeFilter span.active{
  display: inline-block;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 16px;
  font-weight: 400;
  font-size: 0.8em;
  font-family: 'Helvetica';
  margin-right: 4px;
}
section#searchResults{
  padding: 16px;
}
#filterModal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: #00000095;
}
.vueModalInner{
  width: calc(100% - 32px);
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 16px;
}
.vueModalInner{
  width: calc(100% - 32px);
  max-width: 400px;
}
#filterModal h2{
  font-size: 1.4em;
  margin-bottom: 16px;
}
</style>
<style>
section#headerSearch #city_search_wrapper{
  display: inline;
}
section#headerSearch #city_search{
  display: inline;
  width: calc(100% - 100px);
  border-radius: 8px;
  padding: 12px;
  border: none;
  background-color: var(--second-bg-color);
  color: #ffffff;
  padding-left: 36px;
  background-image: url('/public/assets/images/icons/search-grey.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 8px;
}
.simple-typeahead-list{
  position: absolute;
  width: calc(100% - 80px);
  z-index: 19;
  cursor: pointer;
  background-color: var(--second-bg-color);
}
.simple-typeahead-list-item{
  padding: 12px 16px!important;
  transition: 0.3s;
}
.simple-typeahead-list-item:hover{
  background-color: #000000;
}
</style>