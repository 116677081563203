<template>
  <div>
    <main class="content-500">
      <iframe style="width: 100%; height: calc(100vh - 80px);" src="https://clubzettel.de/"></iframe>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

import {notificationStore} from "@/store/notificationStore";
export default {
  name: "Muttizettel",
  title: "Muttizettel",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data(){
    return {

    }
  },
  computed: {
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
  }
}
</script>

<style scoped>
main{
  padding-top: 80px;
}

</style>