<template>
  <main class="content-500 coupons">
    <div class="title">
      <h1>Gutscheine</h1>
      <h2>Exklusiv für dich</h2>
    </div>
    <section class="pad-16">
      <coupon-element v-for="coupon in coupons" :coupon="coupon" :key="coupon.id" />
      <div v-if="!coupons.length" class="t-center">
        <p class="color-white">
          Keine Gutscheine vorhanden.
        </p>
      </div>
    </section>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import couponElement from "@/components/coupons/coupon";
import {aSyncData} from "@/modules/main";


export default {
  name: "Coupons",
  title: "Coupons",
  components: {
    couponElement,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      coupons: [],
    }
  },
  mounted() {
    this.config.loading = false
    this.getCoupons()
  },
  methods: {
    getCoupons(){
      aSyncData('coupon/getCoupons')
          .then(r => {
            if(r.status === 250){
              this.coupons = r.data
            }
          })
    },
  }
}
</script>

<style>
.coupons .title h1{
  margin-top: 16px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
}
.coupons .title h2{
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  text-align: center;
  margin-bottom: 32px;
}

</style>