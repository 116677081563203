<template>
  <div>
    <main class="content-500">
      <section id="eventDetailsHeader">
        <div class="t-right">
          <div class="label button bgcolor-blue color-white">
            {{ eventDetails.categories }}
          </div>
        </div>
        <img class="titleImage" :src="config.projectURL + 'assets/images/events/'+eventDetails.image+'.jpg'">
        <div class="t-center pad-16">
          <h3 style="font-size: 1.8em;">
            {{ eventDetails.title }}
          </h3>
          <h4 class="f-bold color-blue">
            {{ eventDetails.subTitle }}
          </h4>
        </div>
        <div class="grid-12" style="padding-bottom: 32px;">
          <div class="col-sp-6 row-sp-12 pad-4">
            <div class="bgcolor-blue br-10 color-white t-center" style="height: 100%; padding: 16px 16px 0 16px;">
              <svg width="64px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z" fill="#ffffff" class="fill-000000" /></svg>
              <p style="margin-top: 16px;">
                {{ covertStartTime }}
              </p>
            </div>
          </div>
          <div class="col-sp-6 row-sp-12 pad-4">
            <div class="bgcolor-blue br-10 color-white t-center" style="height: 100%; padding: 16px 16px 0 16px;">
              <svg width="64px" viewBox="0 0 91 91" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 91 91"><path d="M66.9 41.8c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4 0 11.3 20.4 32.4 20.4 32.4s20.4-21.1 20.4-32.4zM37 41.4c0-5.2 4.3-9.5 9.5-9.5s9.5 4.2 9.5 9.5c0 5.2-4.2 9.5-9.5 9.5-5.2 0-9.5-4.3-9.5-9.5z" fill="#ffffff" class="fill-000000" /></svg>
              <p style="margin-top: 16px;">
                {{ eventDetails.location }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="eventDetailsContent">
        <div class="pad-16 color-white br-10">
          <p v-html="nl2br(eventDetails.description)" />
        </div>
        <div class="pad-16 t-center" id="eventLabels">
          <eventLabels v-for="elitems in eventLabels" :event-labels="elitems" :key="elitems.id" />
        </div>
      </section>
      <section id="joinCTA">
        <div class="pad-16 t-center">
          <h5 class="color-white f-bold">
            {{ isAttendees ? 'Du nimmst teil!' : 'Bereit für eine verrückte Nacht ?' }}
          </h5>
          <button v-if="!isAttendees" class="button bgcolor-blue color-white" @click="attendee()">
            Jetzt Teilnehmen
          </button>
          <button v-else class="button bgcolor-second color-white" @click="attendee()">
            Absagen
          </button>
          <button class="button bgcolor-second color-white sharebutton">
            <img src="assets/images/icons/share-white.svg" class="width-20">
          </button>
        </div>
      </section>
      <section class="bgcolor-second br-10" id="ticketSale" v-if="parseInt(eventDetails.ticketSale) === 1">
        <div class="pad-16">
          <h4 class="f-bold color-white t-center">
            Jetzt Tickets sichern!
          </h4>
          <div>
            <div class="grid-12" v-if="eventDetails.ticketSaleLink != null && eventDetails.ticketSaleLink !== 'null'">
              <div class="col-sp-6 row-sp-12">
                <p>Tickets über externen Anbieter kaufen:</p>
              </div>
              <div class="col-sp-6 row-sp-12 t-right">
                <a :href="eventDetails.ticketSaleLink" target="_blank" class="button bgcolor-blue color-white">Ticket Shop öffnen</a>
              </div>
            </div>
            <div v-else>
              <ticketItems v-for="titems in ticketItems" :ticket-items="titems" :key="titems.id" />
              <p v-if="!ticketItems.length" class="t-center">
                Aktuell sind keine Tickets verfügbar
              </p>
            </div>
          </div>
        </div>
      </section>
      <div class="pad-32" />
      <section class="bgcolor-second br-10" id="ticketSale" v-if="lounges != ''">
        <div class="pad-16">
          <h4 class="f-bold color-white t-center">
            Jetzt Lounges sichern!
          </h4>
          <div>
            <div>
              <lounge v-for="lounge in lounges" :lounge="lounge" :key="lounge.id" />
            </div>
          </div>
        </div>
      </section>
      <section id="otherEvents">
        <div class="pad-16">
          <h4>
            Weitere <span class="color-blue">Events</span><br>
            die dir gefallen könnten
          </h4>
        </div>
        <section id="searchResults">
          <otherEventsItems v-for="oeitems in otherEventsItems" :other-events-items="oeitems" :key="oeitems.id" />
          <p class="t-center color-white" v-if="otherEventsItems === ''">
            Aktuell gibt es keine weiteren geplanten Events von diesem Veranstalter.
          </p>
        </section>
      </section>
      <transition name="modal">
        <section v-if="media.length">
          <h4 style="font-size: 1.8em;" class="t-center pad-32">
            Medien
          </h4>
          <div class="grid-12">
            <media v-for="singleMedia in media" :key="singleMedia.name" :media="singleMedia" :event-id="eventDetails.id" :event-title="eventDetails.title" />
          </div>
        </section>
      </transition>
    </main>
    <transition name="modal" id="filterModal">
      <div class="vueModal" v-if="filterModal">
        <div class="vueModalOuter" @click="filterModal = false;" />
        <div class="vueModalInner bottom bgcolor-second">
          <div class="t-center pad-8">
            <h2>Events Filtern</h2>
            <button @click="filterModal = false;" class="button fluid bgcolor-blue color-white">
              Okay
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import otherEventsItems from "@/components/eventDetails/otherEvents";
import eventLabels from "@/components/eventDetails/label";
import ticketItems from "@/components/eventDetails/ticket";
import media from "@/components/eventDetails/media";
import {aSyncData, nl2br} from "@/modules/main";
import Lounge from "@/components/eventDetails/lounge";

export default {
  name: "EventDetails",
  title: "EventDetails",
  components: {
    Lounge,
    otherEventsItems,
    eventLabels,
    ticketItems,
    media
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      otherEventsItems: '',
      eventDetails: {},
      eventLabels: '',
      covertStartTime: '',
      isAttendees: false,
      ticketItems: [],
      lounges: '',
      media: [],
      filterModal: false
    }
  },
  computed: {
  },
  mounted() {
    this.config.loading = false
    this.loadDetails()
  },
  methods: {
    loadDetails(){
      aSyncData('eventdetails/getDetails', {eventId: this.$route.params.id})
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.otherEventsItems = r.data.otherEventsItems
              this.eventDetails = r.data.eventDetails
              this.ticketItems = r.data.ticketItems
              this.eventLabels = r.data.eventLabels
              this.lounges = r.data.lounges
              this.isAttendees = r.data.isAttendees
              this.media = r.data.media
              let startTime = r.data.eventDetails.startTime
              let timestampArr = startTime.split(" ");
              let date = timestampArr[0];
              let time = timestampArr[1];
              const dateArr = date.split("-");
              const timeArr = time.split(":");
              this.covertStartTime = dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
            }
          })
    },
    attendee() {
      if(!this.auth.isAuth) this.$router.push('/login?redirect=' + this.$route.path)
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.$route.params.id)
      fD.append('isAttendees', JSON.stringify(this.isAttendees))
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('eventdetails/attendee', fD)
          .then(r => {
            if(r.status === 250) _this.isAttendees = !_this.isAttendees
          })
    },
    nl2br(str){
      return nl2br(str)
    }
  }
}
</script>

<style>
main{
  padding-top: 80px;
}
section#searchResults{
  padding: 16px;
  margin-top: 32px;
}
section#eventDetailsHeader{
  padding: 16px;
}
section#eventDetailsHeader .label{
  margin-right: 16px;
  margin-bottom: -32px;
  position: relative;
  z-index: 3;
  padding: 10px 8px;
  text-transform: none;
}
section#eventDetailsHeader img.titleImage{
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  border: 2px solid #ffffff;
  margin-bottom: 16px;
  margin-top: -8px;
}
section#eventDetailsHeader h4{
  font-size: 1.6em;
  font-family: 'Helvetica';
  font-weight: 600;
  color: rgba(255,255,255,1);
  margin-bottom: 0px;
}
section#eventDetailsHeader .meta{
  padding-top: 4px;
  opacity: 0.6;
}
section#eventDetailsHeader .meta ul{
  padding: 0;
  margin: 0;
}
section#eventDetailsHeader .meta ul li{
  padding: 0;
  margin: 0;
  display: inline;
  margin-right: 12px;
  font-weight: 200;
  font-size: 0.8em;
}
section#eventDetailsHeader .meta ul li img{
vertical-align: -4px;
}
section#eventDetailsContent{
  padding-top: 0;
  margin-top: -16px;
}
section#eventDetailsContent h5{
  font-size: 1.2em;
}
section#eventDetailsContent p{
  margin-top: 8px;
  font-size: 0.95em;
  font-family: 'Helvetica';
  font-weight: 300;
  color: rgba(255,255,255,0.8);
}
section#eventDetailsContent #eventLabels span{
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 16px;
  font-weight: 400;
  font-size: 0.9em;
  font-family: 'Helvetica';
  margin-right: 8px;
  display: inline-block;
  margin-bottom: 8px;
}
section#joinCTA{
  margin-bottom: 32px;
}
section#joinCTA h5{
  margin-bottom: 16px;
  font-size: 1.1em;
  font-weight: 500;
}
section#joinCTA button:not(.sharebutton){
  text-transform: none;
  padding: 12px 32px;
  font-weight: 500;
  font-size: 0.8em;
  margin-right: 16px;
  vertical-align: top;

}
section#joinCTA button.sharebutton{
  padding: 8px 10px;
}
section#ticketSale h4{
  font-size: 1.3em;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 500;
}
section#ticketSale button{
  text-transform: none;
}
section#ticketSale p{
  color: rgba(255,255,255,0.6);
}
section#eventHoster h5{
  font-weight: 300;
  margin-top: 32px;
  font-size: 0.8em;
  margin-bottom: 8px;
}
section#otherEvents h4{
  font-size: 1.5em;
  font-weight: 300;
  margin-top: 32px;
}
section#otherEvents h4 span{
  font-weight: 700;
}
</style>