<template>
  <header>
    <div class="grid-12">
      <div class="col-sp-3 row-sp-12 pad-8" style="padding-top: 10px!important;">
        <img v-if="!config.menuModal" @click="config.menuModal = true; supportModal = false;" src="assets/images/icons/menu-icon.svg" class="width-32 menubtn">
        <img v-else @click="config.menuModal = false" src="assets/images/icons/close-icon.svg" class="width-28 menubtn">
      </div>
      <div class="col-sp-6 row-sp-12 t-center pad-8">
        <router-link to="/home" @click="config.menuModal = false">
          <img src="assets/images/nachtaktivevents-logo.png" class="width-200">
        </router-link>
      </div>
      <div class="col-sp-3 row-sp-12 pad-8 t-right">
        <router-link id="notificationBell" to="/notifications" @click="readNotifications()">
          <img src="assets/images/icons/bell-white.svg" class="width-24" style="vertical-align: top; margin: 10px 8px 0 0;">
          <span v-if="notification.unread > 0 && auth.isAuth">{{ notification.unread }}</span>
        </router-link>
      </div>
    </div>
  </header>
  <transition name="modal" id="MenuModal" style="position: fixed; padding: 16px;">
    <div class="vueModal" v-if="config.menuModal">
      <div class="vueModalOuter">
        <div class="menuContent">
          <ul id="navTop">
            <li>
              <router-link to="/myEvents" @click="config.menuModal = false">
                🎉
                Events
              </router-link>
            </li>
            <li>
              <router-link to="/tickets" @click="config.menuModal = false">
                🎟️
                Meine Tickets
              </router-link>
            </li>
            <li>
              <router-link to="/wallet" @click="config.menuModal = false">
                💰
                Guthaben <button class="button tiny bgcolor-blue color-white">
                  {{ (user.credits / 100).toFixed(2) }}€
                </button>
              </router-link>
            </li>
            <li>
              <router-link to="/coupons" @click="config.menuModal = false">
                🎁
                Gutscheine
              </router-link>
            </li>
            <div class="pad-8" />
            <li>
              <router-link to="/media" @click="config.menuModal = false">
                📷
                Fotos / Videos
              </router-link>
            </li>
            <li>
              <a style="cursor: pointer;" @click="config.menuModal = false; supportModal = true">
                ❓
                Hilfe / FAQ
              </a>
            </li>
            <li>
              <router-link to="/muttizettel" @click="config.menuModal = false">
                📝
                Muttizettel
              </router-link>
            </li>
            <li>
              <a href="https://nachtaktivevents.de/" style="cursor: pointer;">
                🔗
                Über uns
              </a>
            </li>
            <div class="pad-8" />
            <li>
              <router-link to="/settings" @click="config.menuModal = false">
                ⚙️
                Einstellungen
              </router-link>
            </li>
          </ul>
          <!--          <div class="locations" v-if="!auth.isAuth">-->
          <!--            <h4>Beliebte Standorte:</h4>-->
          <!--            <ul>-->
          <!--              <li>-->
          <!--                <router-link to="/city/79" @click="config.menuModal = false">-->
          <!--                  Hamburg-->
          <!--                </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link to="/city/14447" @click="config.menuModal = false">-->
          <!--                  Berlin-->
          <!--                </router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link to="/city/88" @click="config.menuModal = false">-->
          <!--                  Köln-->
          <!--                </router-link>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </div>-->
          <!--          <div class="locations" v-else>-->
          <!--            <h4>Meine Standorte:</h4>-->
          <!--            <ul>-->
          <!--              <myCities v-for="ncitems in navCityItems" :nav-city-items="ncitems" :key="ncitems.id" />-->
          <!--            </ul>-->
          <!--          </div>-->
          <div class="footer">
            <div class="grid-12">
              <div class="col-sp-6 row-sp-12">
                <a href="https://nachtaktivevents.de/impressum/" target="_blank">Impressum</a>
              </div>
              <div class="col-sp-6 row-sp-12">
                <a href="https://nachtaktivevents.de/datenschutz/" target="_blank">Datenschutz</a>
              </div>
            </div>
            <div class="t-center pad-16">
              <a href="https://www.facebook.com/NachtaktivEvent/" target="_blank" style="margin: 0px 8px; display: inline;">
                <svg width="24px" viewBox="0 0 56.693 56.693" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="M40.43 21.739h-7.645v-5.014c0-1.883 1.248-2.322 2.127-2.322h5.395V6.125l-7.43-.029c-8.248 0-10.125 6.174-10.125 10.125v5.518h-4.77v8.53h4.77v24.137h10.033V30.269h6.77l.875-8.53z" fill="#ffffff" class="fill-000000" /></svg>
              </a>
              <a href="https://www.instagram.com/nachtaktiv.events/" target="_blank" style="margin: 0px 8px; display: inline;">
                <svg width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.7 56.7" xml:space="preserve"><path d="M28.2 16.7c-7 0-12.8 5.7-12.8 12.8s5.7 12.8 12.8 12.8S41 36.5 41 29.5s-5.8-12.8-12.8-12.8zm0 21c-4.5 0-8.2-3.7-8.2-8.2s3.7-8.2 8.2-8.2 8.2 3.7 8.2 8.2-3.7 8.2-8.2 8.2z" fill="#ffffff" class="fill-000000" /><circle cx="41.5" cy="16.4" r="2.9" fill="#ffffff" class="fill-000000" /><path d="M49 8.9c-2.6-2.7-6.3-4.1-10.5-4.1H17.9c-8.7 0-14.5 5.8-14.5 14.5v20.5c0 4.3 1.4 8 4.2 10.7 2.7 2.6 6.3 3.9 10.4 3.9h20.4c4.3 0 7.9-1.4 10.5-3.9 2.7-2.6 4.1-6.3 4.1-10.6V19.3c0-4.2-1.4-7.8-4-10.4zm-.4 31c0 3.1-1.1 5.6-2.9 7.3s-4.3 2.6-7.3 2.6H18c-3 0-5.5-.9-7.3-2.6C8.9 45.4 8 42.9 8 39.8V19.3c0-3 .9-5.5 2.7-7.3 1.7-1.7 4.3-2.6 7.3-2.6h20.6c3 0 5.5.9 7.3 2.7 1.7 1.8 2.7 4.3 2.7 7.2v20.6z" fill="#ffffff" class="fill-000000" /></svg>              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" id="SupportModal">
    <div class="vueModal" v-if="supportModal">
      <div class="vueModalOuter" style="position: relative;">
        <div class="vueModalInner largeMax" id="supportChat">
          <section class="pad-16 t-center">
            <h3 style="padding-left: 24px;" class="f-clear">
              Support-Chat
              <div class="f-right" @click="supportModal = 0; supportStep = 0;">
                <img style="cursor: pointer;" src="assets/images/icons/close-icon.svg" class="width-24">
              </div>
            </h3>
          </section>
          <section id="chat">
            <div v-for="chat in chatArray" :key="chat.question" style="display: inline;">
              <div @click="()=>{pushToChat(chat.answers)}" v-html="chat.question" style="display: inline;" />
            </div>
          </section>
          <section id="chatFooter">
            <div>
              <div class="field" v-if="user.email === ''">
                <input type="email" v-model="email" placeholder="E-Mail-Adresse">
              </div>
              <div class="field">
                <textarea name="" v-model="chatMessage" cols="30" rows="3" placeholder="Du hast weitere Fragen? Dann schreibe hier direkt eine Nachricht..." />
              </div>
              <div>
                <button class="button bgcolor-blue color-white fluid" v-if="chatMessage !== '' && email !== ''" @click="sendMessage()">
                  Absenden
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import myCities from "@/components/navBar/myCities";
import {nextTick} from 'vue';
import {questions} from "@/data/questions";
import {notificationStore} from "@/store/notificationStore";

export default {
  name: "NavBar",
  setup(){
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data() {
    return {
      supportModal: false,
      notificationCount: 0,
      navCityItems: '',
      chatArray: [],
      chatStep: 0,
      questionObj: questions,
      chatMessage: '',
      email: this.user.email,
    }
  },
  mounted() {
    this.getNotificationsCount()
    this.getMyCities()
    this.questionObj.map(singleObject =>(
        this.chatArray.push(singleObject)
    ))
  },
  methods:{
    async pushToChat(arr){
      arr.map(singleArr=>(
          this.chatArray.push(singleArr)
      ))
      await nextTick()
      let scroll_to_bottom = document.getElementById('chat');
      scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
    },
    sendMessage(){
      const fD = new FormData()
      fD.append('email', this.email)
      fD.append('chatMessage', this.chatMessage)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('home/sendMessage', fD)
          .then((response) => {
            if(response.status === 250){
              const Messagearray = [{
                question: '<div><span class="label">'+this.chatMessage+'</span></div>',
                answers:[]
              }];
              this.pushToChat(Messagearray)
              const Responsearray = [{
                question: '<div><span class="label blue">Deine Nachricht wurde erfolgreich versendet. Wir werden uns umgehend bei dir via E-Mail melden.</span></div>',
                answers:[]
              }];
              this.pushToChat(Responsearray)
              this.chatMessage = '';
            }
          })
    },
    getNotificationsCount(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/getNotificationsCount', fD)
          .then((response) => {
            if(response.status === 250){
              _this.notificationCount = response.data.length
            }
          })
    },
    readNotifications(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/readNotifications', fD)
          .then((response) => {
            if(response.status === 250){
              _this.notificationCount = 0
            }
          })
    },
    getMyCities(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('limit', 6)
      this.axios.post('home/getMyCities', fD)
          .then((response) => {
            if(response.status === 250){
              _this.navCityItems = response.data
            }
          })
    },
  }
}
</script>

<style scoped>
header{
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  background-color: var(--main-bg-color);
  padding-top: calc(8px + env(safe-area-inset-top));
}
header img.image{
  border: 2px solid #ffffff;
}
header .menubtn{
  background-color: var(--second-bg-color);
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
}
header .menubtn img{
  filter: invert(1);
}
#MenuModal{
  top: 40px;
  height: 100%;
  background: #000000;
  z-index: 60;
  padding-top: calc(16px + env(safe-area-inset-top)) !important;
}
#MenuModal .menuContent{
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  padding-top: 40px;
}
#MenuModal .menuContent #navTop{
  padding: 0;
  margin: 0;
  border-bottom: 2px solid rgba(255,255,255,0.6);
}
#MenuModal .menuContent #navTop li{
  list-style: none;
  display: block;
  margin-bottom: 12px;
}
#MenuModal .menuContent #navTop li a{
  text-decoration: none;
  display: block;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.3em;
  transition: 0.3s;
}
#MenuModal .menuContent #navTop li a button{
  float: right;
  margin-top: -4px;
  margin-left: 8px;
}
#MenuModal .menuContent #navTop li a:hover{
  color: #2068ff;
}
#MenuModal .menuContent .locations h4{
  margin-top: 16px;
  font-size: 1.3em;
  color: #2068ff;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;
}
#MenuModal .menuContent .locations{
  padding: 0;
  margin: 0;
  border-bottom: 2px solid rgba(255,255,255,0.6);
  padding-bottom: 16px;
}
#MenuModal .menuContent .locations li{
  list-style: none;
  display: inline-block;
  margin-bottom: 4px;
  width: 50%;
}
#MenuModal .menuContent .locations li a{
  text-decoration: none;
  display: block;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
  transition: 0.3s;
}
#MenuModal .menuContent .locations li a:hover{
  color: #2068ff;
}
#MenuModal .menuContent .footer{
  padding-top: 16px;
}
#MenuModal .menuContent .footer a{
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  color: #ffffff;
  font-size: 0.9em;
  margin-bottom: 8px;
  transition: 0.3s;
}
#MenuModal .menuContent .footer a:hover{
  color: #2068ff;
}
#notificationBell {
  position: relative;
}
#notificationBell span{
  position: absolute;
  background-color: #2068ff;
  color: #ffffff;
  z-index: 3;
  left: 8px;
  top: 0px;
  border-radius: 90%;
  width: 12px;
  height: 12px;
  font-size: 0.7em;
  font-weight: bold;
  text-align: center;
  vertical-align: center;
  padding: 4px;

}
#SupportModal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #00000033;
  height: calc(100% - 60px);
  z-index: 50;
}
#supportChat{
  position: relative;
  background-color: #000000;
  top: 72px;
  margin: 0 auto;
  max-width: 500px;
  height: calc(100% - 72px);
}
#supportChat section h3{
  font-size: 1.2em;
  font-weight: bold;
}
#supportChat #chat{
  padding: 8px;
  position: absolute;
  top: 60px;
  width: 100%;
  box-sizing: border-box;
  height: calc(100% - 240px);
  overflow-y: scroll;
}
#supportChat #chatFooter{
  padding: 8px 8px 24px 8px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  box-sizing: border-box;
}
#supportChat #chatFooter .label{
  cursor: pointer;
}
#supportChat #chatFooter textarea,
#supportChat #chatFooter input{
  background-color: var(--main-bg-color);
  border: none;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  color: #ffffff;
  margin-bottom: 4px;
}
#supportChat #chatFooter textarea:focus{
  outline: none;
}
</style>
<style>
#supportChat #chat{

}
#supportChat #chat .label{
  display: block;
  margin-bottom: 8px;
  cursor: pointer;
  padding: 8px 14px;
  margin-left: 16px;
  border-top-right-radius: 0px;
}
#supportChat #chat .label.blue{
  display: inline-block;
  background-color: #2068ff!important;
  margin-right: 16px;
  float: left;
  border-top-left-radius: 0px;
  border-top-right-radius: 16px;
}
#supportChat #chat div{
  float: none;
  clear: both;
}
</style>