<template>
  <section>
    <div class="pad-8" />
    <button class="button secondary" @click="$router.push({name: 'EventOverviewView', query: {tab: 'albums'}})">
      Zurück
    </button>
    <div>
      <loader size="small" v-if="loading" />
      <div v-else>
        <div class="pad-16">
          <img class="responsive-img br-10" :src="config.projectURL + config.eventMediaUploadPath + event.image + '_thumb.jpg'">
        </div>
        <h3 class="t-center pad-8" style="font-size: 1.5em;">
          {{ event.title }}
        </h3>
        <div class="grid-12">
          <media v-for="singleMedia in media" :key="singleMedia.name" :media="singleMedia" :event-id="singleMedia.eventId" />
        </div>
        <div v-if="!media.length" class="t-center">
          <p class="color-white">
            Keine Medien vorhanden.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import loader from "@/components/basic/loader";
import {aSyncData} from "@/modules/main";
import media from "@/components/eventDetails/media";
import {configStore} from "@/store/configStore";

export default {
  name: "eventMedia",
  components:{
    loader,
    media
  },
  props: {
    eventId: {
      required: true,
      type: Number
    }
  },
  setup() {
    const config = configStore()

    return {config}
  },
  data() {
    return {
      media: [],
      event: {},
      loading: true
    }
  },
  mounted() {
    this.loadMedia()
  },
  methods: {
    loadMedia(){
      aSyncData('media/getMedia', {eventId: this.eventId})
          .then(r => {
            console.log(r)
            if(r.status === 250) {
              this.media = r.data.media
              this.event = r.data.event
            }
          })
          .catch(e => {console.error(e)})
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>