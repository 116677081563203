export const questions = [
    {
        question:'<h4 class="t-center f-bold pad-8">Wozu hast du Fragen?</h4>',
        answers: []
    },
    {
        question:'<span class="label t-center">🎟️ Tickets</span>',
        answers:[
            {
                question:'<h4 class="t-center f-bold pad-8">Fragen zu Tickets</h4>',
            },
            {
                question:'<span class="label">🎫 SIND DIE TICKETS ÜBERTRAGBAR?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Ja, es ist egal welcher Name darauf steht, du kannst die Tickets auch problemlos weiter verkaufen.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ KANN ICH MEINE TICKETS ZURÜCK GEBEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Du darfst deine Tickets gerne weiterverkaufen / weitergeben, die Tickets sind aber vom Umtausch ausgeschlossen.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ GIBT ES EINE ABENDKASSE?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Sofern wir im Vorverkauf noch nicht ausverkauft sind, gibt es die restlichen Tickets direkt an der Abendkasse zu kaufen. Diese hat ab Veranstaltungsbeginn geöffnet & befindet sich im Kassenbereich der Location.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ WAS BEDEUTEN DIE VERSCHIEDENEN TICKETARTEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Die Tickets haben folgende Vorteile 👇<br><br>' +
                            '👉 EINTRITTS-KARTE' +
                            '<br>✅ EINTRITTSKARTE' +
                            '<br>✅ VERGÜNSTIGTER EINTRITT' +
                            '<br>✅ BEVORZUGTER GAST' +
                            '<br>✅ EINLASS AUCH BEI AUSVERKAUFTEN EVENTS' +
                            '<br><br>👉 DELUXE-TICKET' +
                            '<br>✅ V.I.P.-EINGANG (DRAUßEN)' +
                            '<br>✅ VERKÜRZTES ANSTEHEN' +
                            '<br>✅ EINTRITTSKARTE' +
                            '<br>✅ VERGÜNSTIGTER EINTRITT' +
                            '<br>✅ BEVORZUGTER GAST' +
                            '<br>✅ EINLASS AUCH BEI AUSVERKAUFTEN EVENTS' +
                            '<br><br>👉 PREMIUM-TICKET' +
                            '<br>✅  NACHTAKTIV STOFFBAND' +
                            '<br>✅  5 KNICKLICHTER' +
                            '<br>✅  2 SÜßE SHOTS' +
                            '<br>✅  V.I.P.-EINGANG (DRAUßEN)' +
                            '<br>✅  VERKÜRZTES ANSTEHEN' +
                            '<br>✅  EINTRITTSKARTE' +
                            '<br>✅  VERGÜNSTIGTER EINTRITT' +
                            '<br>✅  BEVORZUGTER GAST' +
                            '<br>✅  EINLASS AUCH BEI AUSVERKAUFTEN EVENTS</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ WAS BEDEUTEN DIE VERSCHIEDENEN TICKETSTUFEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Je früher du das Ticket kaufst, desto günstiger ist es. Jede Ticketstufe gibt es nur für bestimmte Zeiten mit bestimmten Kontigenten.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">✅ WIE LÖSE ICH MEIN TICKET EIN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">' +
                            'Unsere <b>digitalen</b> Tickets kannst du bequem über dein Smartphone vorzeigen. Bitte zeig an der Kasse den QR "rangezoomt" vor.' +
                            '<br><br>' +
                            ' <b>Harttickets</b> (welche du zB ggf. an Vorverkaufsstellen kaufen kannst) - musst du bitte immer im Originalen mitbringen. Hier reicht kein Foto oder ähnliches.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ WAS BEDEUTET KEINE 100% EINLASSGARANTIE?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Für jeden Ticketinhaber haben wir einen Platz "vorgesehen". Wenn diese Person aber stark alkoholisiert ist oder vor Ort negativ auffällt (benimmt sich schlecht), erhält diese Person trotz Ticket keinen Einlass.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ WAS BEDEUTET "BEVORZUGTER" GAST?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Als Ticketinhaber bist du auf unserer Veranstaltung ein bevorzugter Gast. Sollte es draußen z.B. zu einer längeren Schlange kommen, werden wir die Ticketinhaber ggf. vorziehen. Sollten wir einen höheren Andrang als Kapazitäten haben, erhalten zuerst Ticketinhaber Einlass.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🎟️ WO KANN ICH TICKETS FÜR DIE VERANSTALTUNG KAUFEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Bei uns in der App und, wenn dort nicht alle ausverkauft sind, dann an der Abendkasse. (solange der Vorrat reicht)</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ WAS IST, WENN DIE VERANSTALTUNG AUSFÄLLT / VERSCHOBEN WIRD?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Sollte eine Veranstaltung ausfallen oder verschoben werden, so ist dein Ticket automatisch für das nächste Event gültig.' +
                            '<br><br>' +
                            'Wenn du stattdessen lieber dein Geld für das Ticket zurück haben möchtest, so schreib uns eine E-Mail an kontakt@smea.info mit deinen Bestell- und Bankdaten.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🎟️ MEIN TICKET KAM NICHT AN / ICH HABE FALSCHE DATEN ANGEGEBEN</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Schick uns dazu einfach eine E-Mail an kontakt@smea.info mit deinen Bestelldaten und wohin wir dein Ticket verschicken sollen.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">✅ WIE LÖSE ICH MEIN TICKET EIN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Unsere <b>digitalen</b> Tickets kannst du bequem über dein Smartphone vorzeigen. Bitte zeig an der Kasse den QR-Code "rangezoomt" vor.' +
                            '<br><br>' +
                            ' <b>Harttickets</b> (welche du z.B. ,ggf., an Vorverkaufsstellen kaufen kannst) - musst du bitte immer im Original mitbringen. Hier reicht kein Foto oder ähnliches.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">😢 ICH BIN NICHT REINGEKOMMEN!</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Sollte dir der Zutritt zur Veranstaltung verwehrt werden, so schreib uns eine E-Mail an kontakt@smea.info und schildere uns die genaue Situation. Solltest du nicht an der Einlassverweigerung Schuld sein, so erhälst du dein Geld zurück.' +
                            '<br><br>' +
                            '<b>Wichtig:</b> Denk daran, wir leben von Gästen die <b>mit uns feiern</b> - wir haben nichts davon wenn ein Gast der Einlass verwehrt wird. Das Sicherheitspersonal der Location hat das Hausrecht.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">💳 MIT WELCHEN ZAHLUNGSMITTELN KANN ICH DAS TICKET BEZAHLEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Wir akzeptieren Paypal, Klarna und Sofortüberweisung.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🎟️ ICH HAB MEIN TICKET NICHT EINGELÖST - KANN ICH ES FÜR EIN ANDERES EVENT NUTZEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Leider nein, aber schreib uns gerne eine E-Mail an kontakt@smea.info und vielleicht finden wir eine andere Möglichkeit für dich.</span></div>',
                        answers:[]
                    }
                ]
            },
        ]
    },
    {
        question:'<span class="label t-center">ℹ️ Allgemein</span>',
        answers:[
            {
                question:'<h4 class="t-center pad-8 f-bold">Allgemeine Fragen</h4>',
            },
            {
                question:'<span class="label">🧥 GARDEROBE</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Bei allen unseren Veranstaltungen gibt es eine Garderobe. Diese kostet in der Regel zwischen 1-2€ je Kleidungsstück. Manche Garderoben haben auch Kapazitäten um Taschen abzugeben. Wichtig: Die Kapazitäten können aufgebraucht sein, wenn du zu spät zur Veranstaltung kommst. Wenn du genauere Infos haben möchtest, so bitten wir dich direkt die Location zu kontaktieren.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ FUNDSACHEN</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Solltest du etwas verloren haben, so kontaktiere bitte direkt die Location. Alle Sachen die wir finden oder bei uns abgegeben werden, rufen wir direkt am Abend aus oder geben es an der Location ab.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🧥 DRESSCODE</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Jede Diskothek hat ihren eigenen Dresscode. Wir empfehlen ein sauberes Outfit. (Keine Capy / Jogginghose). Wir leben von den Gästen die im Club feiern, nicht vor dem Club, also mach dir nicht zu viele Sorgen :)</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">⌚ WANN SOLLTE ICH KOMMEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Mit einer Vorverkaufskarte ist für dich ein Platz bei der Party gesichert. Wir empfehlen dennoch relativ Zeitnah zum Veranstaltungsbeginn zu kommen, damit du auch alle Specials in vollen Zügen genießt.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">⏰ WANN BEGINNT / ENDET DIE VERANSTALTUNG?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Die genauen Öffnungszeiten findest du auf unseren digitalen Veranstaltungs-Ankündigungen und auf dem Ticket. In der Regel beginnen unsere Veranstaltungen um 22:00 Uhr und enden um 05:00 Uhr morgens.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🎁 HALTET IHR WIRKLICH ALLE SPECIALS AN - DIE IHR ANKÜNDIGT?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Wir halten nicht nur alle Specials ein die wir ankündigen, sondern haben in der Regel für jede Veranstaltung noch weitere Überraschungen im Gepäck.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">💯 WIE & WO KANN ICH EINE VIP-LOUNGE BUCHEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Die VIP-Lounge Verbuchungen laufen immer direkt über die Locations. Besuch hierzu die Website der Location oder kontaktiere diese über deren Kontaktwege.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🪪 WIE KANN ICH MICH AUSWEISEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Wir akzeptieren: Personalausweis / Reisepass. In Ausnahmefällen akzeptiert das Sicherheitspersonal auch den Führerschein. Bilder von Ausweisen oder Ähnliches werden nicht akzeptiert.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🚗 GIBT ES GENÜGEND PARKPLÄTZE VOR ORT?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">In der Regel gibt es genügend Parkplätze in umliegender Nähe.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🎫 ICH HABE NOCH GUTSCHEINE VON DER LOCATION - KANN ICH DIE EINLÖSEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Da wir für diesen Veranstaltungstag die Location gemietet haben, akzeptieren wir leider keine Gutscheine oder ähnliches.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ DARF ICH EIGENE SPEISEN & GETRÄNKE MITNEHMEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Speisen & Getränke dürfen leider nicht mitgebracht werden.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🚞 KOMME ICH GUT MIT DEN ÖFFENTLICHEN VERKEHRSMITTELN ZUM EVENT?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Hierzu empfehlen wir dir Google Maps oder ähnliches zu nutzen. In der Regel aber ja. :)</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">📝 WAS BENÖTIGE ICH ALLES UM MITFEIERN ZU KÖNNEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">🪪 Einen Ausweis' +
                            '<br><br>' +
                            'Wenn du 16/17 Jahre alt bist, benötigst du außerdem 👇' +
                            '<br><br>' +
                            '📝 Einen ausgefüllten Muttizettel' +
                            '<br>' +
                            '👑 Eine volljährige Begleitperson' +
                            '<br>' +
                            'ℹ️ Manche Locations verlangen auch eine Persokopie von dem Elternteil, das den Muttizettel unterschreibt.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🚪 KANN ICH DIE VERANSTALTUNG KURZ VERLASSEN & WIEDER REIN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">In der Regel ist dies nicht möglich.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">💸 WIE SIND DIE GETRÄNKE-PREISE?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Jede Diskothek hat eine unterschiedliche Getränkekarte. In der Regel sind diese “human.” Auf unseren Veranstaltungen gibt es oft Getränke-Specials. Bei expliziten Fragen empfehlen wir direkt die Location zu kontaktieren.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">💳 KANN ICH VOR ORT MIT KARTE ZAHLEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Dies ist je nach Location unterschiedlich. Hierzu empfehlen wir, dich direkt an die Location zu wenden.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">⌚ WIE VIEL WARTEZEIT SOLLTE ICH EINRECHNEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Je nach Location & Vorverkaufsstand sind die Wartezeiten unterschiedlich. Bei allen unseren Veranstaltungen planen wir mehr Personal ein, um einen schnelleren und reibungsloseren Ablauf zu garantieren. Am Eingangsbereich können wir leider keine Leute “reinbeamen”. In der Regel gibt es einen Eingang durch den jeder Gast durch muss. Die Sicherheit unserer Gäste & Mitarbeiter liegt uns am Herzen, sodass wir eine Identitäts-, Körper- & Taschenkontrolle durchführen müssen. Zusätzlich müssen wir die Coronaauflagen erfüllen & die Nachweise kontrollieren. Diese Sicherheitsauflagen bringen einen Zeitaufwand mit sich, den man leider nicht umgehen kann. Bei Locations wo es platztechnisch möglich ist, haben wir einen Pre Check-In. Diesen kündigen wir dann auf den Instagram Kanälen an. Falls es mal zu längeren Wartezeiten kommt, so darfst du dir sicher sein, dass wir alles geben, um den Gast schnellstmöglich drinnen zu haben, denn wir leben von den Gästen die im Club feiern, nicht davor. :)</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🚽 GIBT ES VOR ORT TOILETTEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Ja</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🎂 VORTEILE AM GEBURTSTAG?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Wir haben Partykonzepte mit Vorteilen für Geburtstagskinder. Eine geile Party wirst du in jedem Fall haben! Wenn ihr eine größere Gruppe seid, so schreibt uns gerne an, vielleicht fällt uns ja etwas ein.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ AN WEM WENDE ICH MICH BEI FRAGEN VOR ORT?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Unser Team ist mit gekennzeichnetem Nachtaktiv Outfit vor Ort auffindbar. Ansonsten findest du uns in den ersten zwei Stunden am Promotion-/Infostand oder draußen im Eingangsbereich. Ansonsten frag gerne das Sicherheitspersonal nach dem Veranstalter.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">📍 WANN & WO FINDEN DIE SPECIALS STATT?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Die meisten Specials finden im großen (Main) Raum ab 00/00:30 Uhr bis 02:30/03:00 Uhr statt. Je nach Gästeandrang können sich diese Zeiten nach hinten verschieben, da wir jedem die Chance geben möchten, die Specials mit zu feiern. Wir kündigen jedes Special im Vorfeld über die DJs an.</span></div>',
                        answers:[]
                    }
                ]
            },
        ]
    },
    {
        question:'<span class="label t-center">🔞 U18</span>',
        answers:[
            {
                question:'<h4 class="t-center pad-8 f-bold">Fragen zu U18</h4>',
            },
            {
                question:'<span class="label">👶 KANN ICH AUCH MIT UNTER 16 JAHREN MITFEIERN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Der Einlass auf unseren Veranstaltungen (bei 16+ Veranstaltungen) ist ab 16 Jahren. Ausnahmen werden hier nicht gemacht.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🔞 WIE VIELE U18 KANN EIN VOLLJÄHRIGER GAST BEAUFSICHTIGEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Dies ist je nach Location unterschiedlich. In der Regel liegen wir hier zwischen 1-3 Personen. Kontaktier dafür optimal direkt die Location.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">📝 WOHER ERHALTE ICH DEN MUTTIZETTEL?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Diesen findest du bei uns in der App oder verlinkt auf unserer Homepage. Unseren digitalen Muttizettel brauchst du nicht ausdrucken, sondern kannst diesen bequem digital auf deinen Handy vorzeigen.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🍷 DARF ICH ALS MINDERJÄHRIGER GAST ALKOHOL TRINKEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Nur die Spirituosen, die in Deutschland ab 16 Jahren frei erwerblich sind.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">⏰ ICH BIN FRÜHER ALS MEINE BEGLEITUNG DA, DARF ICH SCHON REIN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Die Veranstaltung musst du gemeinsam mit deiner Aufsichtsperson betreten.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🤔 MUSS ICH ALS MINDERJÄHRIGER DIE PARTY MIT MEINER BEGLEITUNG GEMEINSAM VERLASSEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Ja</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🍷 DARF ICH ALS MINDERJÄHRIGER GAST ALKOHOL TRINKEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Nur die Spirituosen, die in Deutschland ab 16 Jahren frei erwerblich sind.</span></div>',
                        answers:[]
                    }
                ]
            },
        ]
    },
    {
        question:'<span class="label t-center">🎵 Musik</span>',
        answers:[
            {
                question:'<h4 class="t-center pad-8 f-bold">Fragen zur Musik</h4>',
            },
            {
                question:'<span class="label">🎧 DJs</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Welche DJs legen auf?' +
                            '<br><br>' +
                            'Bei unseren Veranstaltungen legen die unterschiedlichsten "Tour-DJs" aus dem europäischen Raum auf. Auf unserer Website findest du ein paar Eindrücke unserer DJs. Auf den jeweiligen Partyaccounts kündigen wir den DJ für jede Veranstaltung an.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🎼 MUSIK</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Welche Musik wird gespielt?' +
                            '<br><br>' +
                            'Auf jeder Veranstaltung gibt es bei uns mindestens den "Main-Raum" - in diesem Raum finden die meisten Specials, sowie der Großteil unserer Show, ab. In diesem Raum spielen wir kommerzielle Musik (Charts, EDM, Black, etc.) - alles wozu man gut tanzen / feiern kann. Je nach Location haben wir noch weitere zusätzliche Räume, in dem verschiedene Musikrichtungen laufen. In der Regel ist der zweite Raum ein Black, RnB, Hip Hop & Deutschrap Raum.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🎹 LIEDER-WÜNSCHE</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Schick uns eine Whatsapp-Nachricht mit dem Clubnamen & deinen Liederwunsch an: +49 176 23904902 - ansonsten schreib deinen Wunschsong gerne lesbar auf einem Zettel & frag höflich den DJ ob er diesen annimmt.</span></div>',
                        answers:[]
                    }
                ]
            },
        ]
    },
    {
        question:'<span class="label t-center">💪 Jobs</span>',
        answers:[
            {
                question:'<h4 class="t-center pad-8 f-bold">Fragen zu Jobs</h4>',
            },
            {
                question:'<span class="label">💪 ICH WÜRDE GERNE FÜR EUCH ARBEITEN</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Dann füll doch jetzt das Kontaktformular in unserer App oder auf unserer Homepage aus.</span></div>',
                        answers:[]
                    }
                ]
            },
        ]
    },
    {
        question:'<span class="label t-center">📝 Gästeliste</span>',
        answers:[
            {
                question:'<h4 class="t-center pad-8 f-bold">Fragen zur Gästeliste</h4>',
            },
            {
                question:'<span class="label">📝 WIE FUNKTIONIERT DAS MIT DER GÄSTELISTE?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Schreib uns deinen Vor- und Nachnamen per Nachricht und du stehst automatisch auf der Gästeliste. Dies bitten wir dich auch zu tun, wenn du ein Ticket hast.' +
                            '<br><br>' +
                            'So wissen wir das du an der Party teilnimmst & können besser planen.</span></div>',
                        answers:[]
                    }
                ]
            },
        ]
    },
    {
        question:'<span class="label t-center">❔ Sonstiges</span>',
        answers:[
            {
                question:'<span class="label blue">Bitte schreibe uns eine Nachricht mit deiner Frage in dem untenstehenden Nachrichtenfeld.</span>',
            },
        ]
    },
]