<template>
  <div class="uploadelement col-sp-6 row-sp-12 pad-4">
    <div class="uploadelement-box">
      <div class="br-10 bgcolor-black">
        <div class="">
          <div class="imageBox" @click="popup = true">
            <img :src="config.projectURL + config.eventMediaUploadPath + eventId + '/' + media.name + '_thumb.jpg'" style="height: 100%; width: 100%" v-if="['jpg', 'jpeg', 'gif', 'png', 'webp'].includes(media.extension)">
            <video :src="config.projectURL + config.eventMediaUploadPath + eventId + '/' + media.name + '.' + media.extension" style="height: 100%; width: 100%" muted playsinline v-else-if="['mp4', 'webm'].includes(media.extension)" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <VueModal :show="popup" approve="Herunterladen" cancel="Schließen" @cancel="popup = false" @close="popup = false" @approve="download">
    <img :src="config.projectURL + config.eventMediaUploadPath + eventId + '/' + media.name + '.' + media.extension" style="height: 100%; width: 100%" v-if="['jpg', 'jpeg', 'gif', 'png', 'webp'].includes(media.extension)">
    <video :src="config.projectURL + config.eventMediaUploadPath + eventId + '/' + media.name + '.' + media.extension" style="height: 100%; width: 100%" controls playsinline v-else-if="['mp4', 'webm'].includes(media.extension)" />
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "media",
  props: {
    media: Array,
    eventId: Number,
    eventTitle: String
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      popup: false
    }
  },
  methods: {
    download(){
      const link = document.createElement('a')
      link.href = this.config.projectURL + this.config.eventMediaUploadPath + this.eventId + '/' + this.media.name + '.' + this.media.extension
      link.download = this.eventTitle + '_' + this.media.name + '.' + this.media.extension
      link.click();
    }
  }
}
</script>

<style scoped>
.uploadelement{
  position: relative;
}
.loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 8px;
  font-size: 1.2em;
  color: rgba(0,0,0,0.6);
  height: 100%;
}
.loader .loader-animation{
  width: calc(100% - 64px);
  height: 32px;
  position: absolute;
  top: 100px;
  left: 32px;
  background-color: rgba(0,0,0,0.05);
  border: 4px solid #135969;
  border-radius: 4px;
}
.loader .loader-animation .topleft{
  position: absolute;
  background-color: #135969;
  width: 16px;
  height: 4px;
  top: -4px;
  left: -16px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.loader .loader-animation .toptop{
  position: absolute;
  background-color: #135969;
  width: 4px;
  height: 12px;
  top: -20px;
  left: -4px;
  border-radius: 4px;
}
.loader .loader-animation .bottomright{
  position: absolute;
  background-color: #135969;
  width: 16px;
  height: 4px;
  bottom: -4px;
  right: -16px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.loader .loader-animation .bottombottom{
  position: absolute;
  background-color: #135969;
  width: 4px;
  height: 16px;
  bottom: -16px;
  right: -4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.loader .loader-animation .process{
  position: absolute;
  color: #ffffff;
  background-color: #ff8565;
  top: 0;
  left: 0;
  height: 100%;
  text-align: center;
  line-height: 32px;
}
.loader h6{
  position: absolute;
  width: 100%;
  top: 160px;
}
.imageBox{
  overflow: hidden;
  width: 100%;
  height: 180px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}
.imageBox img{
  width: auto;
  height: 100%;
  max-height: 200px;
  border-radius: 8px;
}

</style>